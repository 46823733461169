import React from 'react';
import Link from 'next/link';
import { XIcon } from '@heroicons/react/solid';
import { useSessionStorage } from 'usehooks-ts';

import { useBanner } from '@/hooks/banners/use-banner';

import { BannerType } from '@/types/Banner';

const GenericBanner = () => {
  const { data: banner, isLoading } = useBanner(BannerType.GENERIC);
  const [closed, setClosed] = useSessionStorage(BannerType.GENERIC, false);

  if (!banner || isLoading || closed) {
    return null;
  }

  return (
    <div className="bg-black">
      <div className="max-w-1680 mx-auto flex text-white py-3 px-6 md:px-10 xl:px-16">
        <div className="flex-1 flex flex-col space-y-1 md:flex-row md:justify-center md:space-y-0 md:space-x-6">
          <p className="text-xs lg:font-bold font-medium uppercase lg:tracking-uyn tracking-wide md:text-sm">
            {banner.text}
          </p>
          {banner.cta_text && banner.cta_link && (
            <Link href={banner.cta_link} aria-label={banner.cta_text}>
              <a className="text-xs uppercase underline lg:tracking-uyn tracking-wide md:text-sm">
                {banner.cta_text} &gt;
              </a>
            </Link>
          )}
        </div>

        <button onClick={() => setClosed(true)} className="ml-auto">
          <XIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default GenericBanner;
